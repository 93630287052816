@import "~react-image-gallery/styles/css/image-gallery.css";
.ui.container {
  padding-top: 4em;
}

.ui.image {
  padding: 1em 0;
}

.labeled.button {
  margin: 1em 0;
}

.extra.content {
  padding: 0.5em 0;
}

.main {
  background-size: cover;
  background-position: center center;
}
.login-box {
  max-width: 450px;
  background-color: rgba(0, 0, 0, 0.4);
}

#root {
  height: 100%;
}


.input-form h3.ui.header {
  padding-bottom: 1em;
}

.edit-restaurant {
  padding-top: 1em;
}
.edit-restaurant .top.attached.menu {
  font-size: 16px;
  margin-bottom: 2em;
}

.category span {
  font-size: 17px;
  padding-right: 1em;
}

.category .dish {
  padding-left: 4em;
}

.category .dish span:before {
  content: '';
  display: inline-block;
  width: 1px;
  height: 1px;
  margin-right: 0.2em;
  border: solid;
  border-color: transparent transparent transparent #51bbaf;
  border-width: 0.4em 0 0.4em 0.4em;
  vertical-align: middle;
}

.category .ui.input input {
  margin-right: 1em;
}

.ui.button.newRestaurantButton {
  margin-top: 1.5em;
}

.ui.button.checkout-button {
  width: 100%;
  margin-top: 1em;
}

.ui.button.decline-button {
  margin-top: 1em;
  margin-left: 1em;
}

.rest-header, .rest-menu  {
  padding-top: 1em;
}

.rest-menu h3 {
  padding-top: 0.5em;
}

.ui.list.menu-list{
  padding-bottom: 1em;
}
.ui.list.menu-list:last-child {
  padding-bottom: 1em;
}

.ui.list .item{
  padding-bottom:0.5em;
}

.listitem-price {
  float: right;
}

.image-gallery {
  padding-bottom: 1em;
}

.order-date {
  padding-bottom: 1em;
}

.order-date div {
  width: 100%;
}

.order-date, .number-of-guests {
  display: flex!important;
  justify-content: center;
}

.five.cards .ui.medium.image {
  width: 100%;
}

.summary-image {
  margin: 0!important;
}

.ui.button.proceed-to-checkout {
  display: flex;
  margin-top: 1em;
}
.restaurant-infos {
  /*border: 1px solid rgba(34,36,38,.1);*/
  grid-area: restaurant-info;
}

.restaurant-infos .ui.segment {
  padding-left: 0;
}

.restaurant-infos .ui.segment {
  border: 0;
}

.logoLink {
  width: 160px;
  height: 30px;
}

.summary-step {
  margin-top: 2em;
}

.summary-list {
  font-size: 18px;
  margin-top: 1em;
}

.ui.segment {
  border: 0;
  box-shadow: none;
  border-top: 1px solid rgba(34,36,38,.15);
}

.number-of-guests input {
  padding-right: 39px!important;
}

.proceed-to-checkout {
  width: 100%;
  justify-content: center;
}

.summary-checkout {
  max-width: 250px;
}

.reservation-info {
  grid-area: reservation-info;
  display: flex;
  justify-content: center;
}

.restaurant-container {
  width: calc(90% - 2em);
  display: grid;
  grid-template: 'restaurant-info reservation-info' auto / 70% 30%;
  gap: 30px;
  margin: 60px auto 0 auto;
}

/*
.reservation-container {
  border: 1px solid rgb(153 153 153 / 40%);
  padding: 2em;
  position: fixed;
  top: 130px;
}
 */

.image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: calc(70vh - 80px);
}



.header-banner {
  background-color: #999999;
}

.header-banner .sticky {
  background-color: #999999;
}

.ui.bottom.attached.segment {
  border: 0
}

/*
.ui.attached.tabular.menu {
  border-bottom: 0;
  position: fixed;
  top: 72px;
  height: 55px;
  z-index: 2;
}
*/

.ui.attached.tabular.menu .item {
  background-color: #fff;
  border: 0;
}

.ui.bottom.attached.segment.active.tab {
  margin-top: 60px;
}

@media only screen and (max-width: 1024px) {
  .restaurant-container {
    grid-template: 'restaurant-info' auto 'reservation-info' auto / 100% 100%;
    margin: 0 5px 20px 5px;
    gap: 10px;
    width: 100%;
  }
  .restaurant-container .ui.attached.tabular.menu .item {
    padding-left: 0;
  }

  .reservation-container {
    position: initial;
    top: 0;
  }

  .ui.attached.tabular.menu {
    position: initial;
    top: initial;
  }

  .ui.bottom.attached.segment.active.tab {
    margin-top: 0px;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px); }
}

.ui.card.disabled-property {
  background-color: lightgray;
}

/*
Semantic ui overwrite
 */
.ui.vertical.segment {
  border:0;
  background-color: rgba(0, 0, 0, 0.4);
}
.ui.secondary.inverted.pointing.menu {
  border: 0;
}
.ui.container {
  padding-top: 0;
}
.ui.menu.tabular .active.item {
  border-bottom: 2px solid black;
}

.ui.container.fullwidth{
  width: 100%;
  background: url("/images/logo.png") no-repeat 20px;
  background-size: contain;
;
}
.ui.container {
  width: 1300px;
}
.content .description, .content .header {
  display: flex!important;
  justify-content: space-between;
}

.content .header .review {
  font-weight: 300;
  font-size: .75em;
}

@media only screen and (max-width: 800px) {
  .ui.five.cards > .card {
    width: calc(100% - 2em);
  }
}
.ui.grid {
  margin-top: 4em;
}

.ui .image.small>img {
  height: 150px;
  width: auto;
}

.reviews h2.ui.header {
  border-bottom: 2px solid #21ba45;
  margin-bottom: 1em;
}

.review-container {
  margin-bottom: 3rem;
  border-bottom: 1px solid #21ba45;
}

.review-container .text {
  padding: 1em 0;
}

.ui.button.write-review {
  margin-bottom: 1em;
}
.new-review {
  margin: 1em 0;
}
.new-review .ui.button {
  margin: 1em 0;
}

/* Fix Sidebar issue - https://stackoverflow.com/questions/46059637/semantic-ui-react-fixed-sidebar */
.pushable:not(body) {
  transform: none;
}
.pushable:not(body) > .ui.sidebar,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}

.ui.list>.item .header {
  background-image: linear-gradient(to right, #000 10%, rgba(255, 255, 255, 0) 0%);
  background-position: 0 14px;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  width: 100%;
  height: 25px;
}

.listitem-name {
  background: #fff;
}

.listitem-price {
  background: #fff;
  text-align: right;
}

.checkbox {
  margin-right: 10px;
}

.ui.vertical.segment {
  background-color: #00b5ad;
}

.request-transfer {
  margin-top: 3em;
}

.request-transfer .segment {
  /*display: flex;
  justify-content: space-evenly;
  align-items: center;*/

  font-weight: 700;
  font-size: 1.3em;
}

.request-transfer .segment div {
  margin-top: 1em;
}
